<template>
  <div class="search-warpper formPart">
    <el-form :inline="true" :model="query" class="demo-form-inline">
      <el-form-item label="发票ID：">
        <el-input
          @change="$emit(`search`)"
          size="mini"
          style="width: 240px"
          clearable
          placeholder="请输入发票ID"
          v-model="query.invoiceCodeKeyWord"
        ></el-input>
        <el-form-item label="分销商：" class="ml15">
          <el-input
            @change="$emit(`search`)"
            size="mini"
            style="width: 240px"
            clearable
            placeholder="请输入分销商"
            v-model="query.nickNameKeyWord"
          ></el-input>
        </el-form-item>
      </el-form-item>
      <el-form-item label="发票选项：">
        <el-select
          size="mini"
          @change="$emit(`search`)"
          style="width: 200px"
          v-model="query.invoiceTitleType"
          clearable
          placeholder="请选择发票选项"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票抬头：">
        <el-input
          @change="$emit(`search`)"
          size="mini"
          clearable
          style="width: 240px"
          placeholder="请输入发票抬头"
          v-model="query.invoiceTitleKeyWord"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          v-model="query.createTime"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          placeholder="请选择创建时间"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 300px"
          @change="$emit(`search`)"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select
          size="mini"
          @change="$emit(`search`)"
          style="width: 200px"
          v-model="query.invoiceStatus"
          clearable
          placeholder="请选择审核状态"
        >
          <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
          type="primary"
          @click="$emit(`search`)"
          icon="el-icon-search"
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        {
          label: '企业',
          value: 1
        },
        {
          label: '个人',
          value: 2
        }
      ],
      stateList: [
        {
          label: '待审核',
          value: 1
        },
        {
          label: '已通过',
          value: 2
        },
        {
          label: '已拒绝',
          value: 3
        }
      ]
    }
  },
  created() {
    //请求调用会接口，将detail赋值给options
  }
}
</script>

<style lang="scss" scoped>
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .ml15 {
    margin-left: 15px;
  }
  .el-form-item {
    margin-right: 20px;
  }
}
</style>

<template>
  <div>
    <!-- <el-button size="mini" type="primary" @click="handleSetted">开票税点设置</el-button> -->
    <BaseDialog width="25vw" title="税点设置" :dialogVisible.sync="visible">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="开票纳税点：" prop="taxRate">
          <el-input style="width: 250px" size="small" v-model="form.taxRate"></el-input>
          <span class="ml10">%</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <loading-btn type="primary" @click="doSubmit">确 定</loading-btn>
        <el-button @click="cancel">取 消</el-button>
      </span>
    </BaseDialog>
  </div>
</template>

<script>
import { deepClone, getDiffData } from '@/components/avue/utils/util'

export default {
  props: {
    sup_this: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validateRate = (rule, value, callback) => {
      if (Number(value) >= 0 && Number(value) <= 100) callback()
      else {
        return callback('请输入0到100之间的数')
      }
    }
    return {
      visible: false,
      form: {
        taxRate: null
      },
      formCopy: {},
      rules: {
        taxRate: [
          { required: true, message: '纳税点不能为空', trigger: 'blur' },
          { validator: validateRate, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        //调用接口并将接口返回信息传递给form.taxRate
        this.formCopy = deepClone(this.form)
      }
    }
  },
  methods: {
    handleSetted() {
      this.visible = true
    },
    cancel() {
      this.visible = false
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate()
      } catch (error) {
        return false
      }
      const data = {
        taxRate: this.form.taxRate
      }
      if (!getDiffData(this.form, this.formCopy)) {
        this.visible = false
        this.$message.success('税点设置成功')
        return
      }
      try {
        //调用接口
        this.visible = false
        this.sup_this.init()
        this.$message.success('税点设置成功')
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped></style>

<template>
  <el-dialog
    style="margin-top: -85px"
    title="提交审核"
    :visible.sync="visible"
    width="972px"
    class="dialog-warpper"
    :before-close="handleClose"
  >
    <div>
      <div class="title">发票详情</div>

      <div class="flex-warpper">
        <div class="flex-item">
          发票选项:
          <span class="normal" v-if="reviewData.invoiceTitleType === 1">企业</span>
          <span class="normal" v-if="reviewData.invoiceTitleType === 2">个人</span>
        </div>

        <div class="flex-item">
          发票类型:
          <span class="normal" v-if="reviewData.invoiceCategory === 1"> 普通发票 </span>
          <span class="normal" v-if="reviewData.invoiceCategory === 2"> 专用发票</span>
        </div>

        <div class="flex-item">
          发票抬头:
          <span class="normal">{{ reviewData.invoiceTitle }}</span>
        </div>

        <div class="flex-item">
          税号:
          <span class="normal">{{ reviewData.companyTaxNo }}</span>
        </div>

        <div class="flex-item">
          电话：
          <span class="normal">{{ reviewData.companyPhone }}</span>
        </div>

        <div class="flex-item">
          注册地址:
          <span class="normal">{{ reviewData.companyRegisteredAddress }}</span>
        </div>

        <div class="flex-item">
          开户银行:
          <span class="normal">{{ reviewData.companyOpeningBankName }}</span>
        </div>

        <div class="flex-item">
          银行账号:
          <span class="normal">{{ reviewData.companyBankAccountNo }}</span>
        </div>

        <div class="flex-item">
          收件人姓名:
          <span class="normal">{{ reviewData.consigneeName }}</span>
        </div>

        <div class="flex-item">
          收件人手机号:
          <span class="normal">{{ reviewData.consigneeMobile }}</span>
        </div>
        <div class="flex-item">
          收件邮箱:
          <span class="normal">{{ reviewData.consigneeEmail }}</span>
        </div>
      </div>
      <div
        style="
          background-color: #f7f8fa;
          display: flex;
          justify-content: space-between;
          margim-top: 20px;
          height: 40px;
          line-height: 40px;
        "
      >
        <div class="title" style="height: 40px; line-height: 40px">产品信息</div>
        <div>
          <el-button icon="el-icon-download" type="text" @click="exportProduct(2)">按款式导出</el-button>
          <el-button icon="el-icon-download" type="text" @click="exportProduct(1)">按订单导出</el-button>
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; height: 48px; line-height: 48px">
        <div>
          <span style="margin-right: 40px">货款总计： ￥{{ reviewData.paymentOrderTotalPrice }} </span>
          运费总计： ￥{{ reviewData.paymentOrderTotalFreight }}
        </div>
        <!-- <PaginationBar :page="page" :size="size" :total="itemTotal" @refreshTableEventFun="refreshTableEventFun" /> -->
      </div>
      <CommonTable height="250px" :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="infoData">
        <template #priceSlot="{ scoped: row }"> ￥{{ row.realUnitPrice }}</template>
        <template #moneySlot="{ scoped: row }"> ￥{{ row.totalPrice }} </template>
      </CommonTable>
      <div style="display: flex; justify-content: space-between; height: 48px; line-height: 48px">
        <div>
          <span style="margin-right: 40px">
            <!-- 货款总计： ￥{{ reviewData.paymentOrderTotalPrice }} </span> -->
            <!-- 运费总计： ￥{{ reviewData.paymentOrderTotalFreight }} -->
          </span>
        </div>
        <PaginationBar :page="page" :size="size" :total="itemTotal" @refreshTableEventFun="refreshTableEventFun" />
      </div>
      <div class="title" style="margin-bottom: 20px">审核</div>
      <el-form label-width="80px" style="margin-bottom: 20px">
        <el-form-item label="是否通过:">
          <el-radio-group v-model="radio">
            <el-radio :label="0">通过</el-radio>
            <el-radio :label="1">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="onBack">返回</el-button>
        <el-button size="mini" :loading="loading" type="primary" @click="onConfirm">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import saveAs from 'jszip/vendor/FileSaver.js'
import { allowInvoice, refuseInvoice, productExport } from '@/api/invoicingReview'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { cols } from './cols'
export default {
  mixins: [initDataMixin],
  data() {
    return {
      pageSize: 10,
      pageIndex: 1,
      radio: 0,
      loading: false,
      url: '/externaladmin/financeService/applyInvoice/invoiceExamine',
      cols,
      reviewData: {}
      // initBool: false
    }
  },
  computed: {
    //前端处理分页
    infoData({ reviewData: { orderItemList } }) {
      return (orderItemList || []).slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize)
    },
    itemTotal({ reviewData: { orderItemList } }) {
      return (orderItemList || []).length
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    resetMergeData: {
      type: Object
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    parseTime,
    initCallBack({ detail }) {
      this.reviewData = detail
    },

    refreshTableEventFun({ page, size }) {
      this.pageSize = size
      this.pageIndex = page
    },

    handleClose() {
      this.$emit('update:visible', false)
      this.radio = 0
    },
    exportProduct(type) {
      if (!this.reviewData.orderItemList || this.reviewData.orderItemList.length === 0)
        return this.$message.warning('发票无关联产品')
      productExport({ id: this.reviewData.id, exportDataType: type })
        .then((res) => {
          const fileName = type == 2 ? '按款式导出的发票详情数据' : '按订单导出的发票详情数据'
          saveAs(res, fileName)
          this.$message.success('导出成功')
        })
        .catch(() => {
          this.$message.error('导出失败')
        })
      // console.log('this.reviewdata',this.reviewData.id);
    },
    onBack() {
      this.handleClose()
    },
    async onConfirm() {
      try {
        this.loading = true
        //调用接口，判断是否通过
        if (this.radio === 0) {
          const res = await allowInvoice({ id: this.reviewData.id })
        } else {
          const res = await refuseInvoice({ id: this.reviewData.id })
        }
        // console.log('res')
        this.loading = false
        this.handleClose()
        this.sup_this.init()
        this.$message.success(this.radio === 0 ? '已通过审核' : '已拒绝审核')
      } catch (e) {
        this.loading = false
        this.$message.success('审核操作失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  font-size: 14px;
  .title {
    color: #595961;
    margin-bottom: 30px;
    font-weight: 500;
    background-color: #f7f8fa;
    height: 32px;
    line-height: 32px;
  }
  .flex-warpper {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    .flex-item {
      flex: 1;
      width: 33.3%;
      min-width: 33.3%; // 加入这两个后每个item的宽度就生效了
      max-width: 33.3%;
      color: #969799;
      margin-bottom: 20px;
      .normal {
        color: #2d2d2d;
      }
    }
  }
  ::v-deep {
    .el-form-item__label {
      color: #969799;
      font-weight: 400;
    }
    .el-radio__label {
      color: #2d2d2d;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>

<template>
  <el-dialog title="录入物流信息" :visible.sync="show" width="480px" class="dialog-warpper" :before-close="handleClose">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="物流方式" prop="expressShippingMethodId">
        <el-select
          size="small"
          style="width: 250px"
          v-model="form.expressShippingMethodId"
          clearable
          placeholder="请输入物流方式"
        >
          <el-option v-for="item in expressData" :key="item.id" :label="item.cnName" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="运单号" prop="expressWaybillCode">
        <el-input
          placeholder="请输入数字、字母或者英文下划线"
          style="width: 250px"
          size="small"
          v-model="form.expressWaybillCode"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNumber">
        <el-input
          placeholder="请输入发票号码"
          style="width: 250px"
          size="small"
          v-model="form.invoiceNumber"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCanceled">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addInvoiceExpress } from '@/api/invoicingReview'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
export default {
  data() {
    var validateOrderNumber = (rule, value, callback) => {
      if (!/^[A-Za-z0-9_/ ]+$/.test(value)) {
        callback(new Error('请输入合法的运单号'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      rules: {
        expressShippingMethodId: [{ required: true, message: '物流方式不能为空', trigger: 'blur' }],
        expressWaybillCode: [
          { required: true, message: '运单号不能为空', trigger: 'blur' },
          { validator: validateOrderNumber, trigger: 'blur' }
        ],
        invoiceNumber:[
          { required: true, message: '发票号码不能为空', trigger: 'blur' }
        ]
      },
      formCopy: {}
    }
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false
    },
    expressData: {
      type: Array,
      default: []
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    balanceVisible: {
      handler(val) {
        this.formCopy = val ? deepClone(this.form) : {}
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false)
      this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
    },
    onSuccessMessage() {
      this.handleClose()
      this.sup_this.init()
      this.$message.success('录入成功')
    },
    handleConfirmed() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        // if (!getDiffData(this.form, this.formCopy)) {
        //   this.onSuccessMessage()
        //   return
        // }
        try {
          this.loading = true
          const res = await addInvoiceExpress(this.form)
          console.log('res', res)
          this.onSuccessMessage()
          this.loading = false
        } catch (e) {
          this.$message.error('录入物流失败')
          this.loading = false
        }
      })
    },
    handleCanceled() {
      // this.$emit('update:form', this.formCopy)
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>

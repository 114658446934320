<template>
  <div class="app-container">
    <taxSetting :sup_this="sup_this" v-p="['externaladmin:financialManagement:invoicingReview:set']" />
    <searchHeader
      v-p="['externaladmin:financialManagement:invoicingReview:search']"
      :query="query"
      @search="search"
    ></searchHeader>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable v-loading="tableLoading" :selection="false" :cols="cols" :infoData="data">
      <template #typeSlot="{ scoped: row }">
        {{ row.invoiceCategory === 1 ? '普通发票' : '专用发票' }}
      </template>

      <template #selectSlot="{ scoped: row }">
        {{ row.invoiceTitleType == 1 ? '企业' : '个人' }}
      </template>

      <template #moneySlot="{ scoped: row }"> ￥{{ row.invoiceAmount }} </template>

      <template #expressSlot="{ scoped: row }">
        <div>{{ row.expressName }}</div>
        <div>{{ row.expressWaybillCode }}</div>
      </template>

      <template #applyTimeSlot="{ scoped: row }">{{ parseTime(row[row.prop]) }}</template>
      <template #invoiceStatusSlot="{ scoped: row }">{{ invoiceStatus(row) }}</template>
      <el-table-column align="center" fixed="right" width="200" label="操作">
        <template slot-scope="{ row }">
          <loadingBtn
            type="text"
            class="menu-btn-item"
            v-p="['externaladmin:financialManagement:invoicingReview:review']"
            v-if="row.invoiceStatus == 1"
            @click="onReview(row)"
            >审核</loadingBtn
          >
          <loadingBtn
            type="text"
            v-p="['externaladmin:financialManagement:invoicingReview:fedex']"
            class="menu-btn-item"
            v-if="row.invoiceStatus == 2"
            @click="saveExpress(row)"
            >录入物流</loadingBtn
          >
        </template>
      </el-table-column>
    </CommonTable>
    <review-dialog :resetMergeData="resetMergeData" v-if="visible" :sup_this="sup_this" :visible.sync="visible" />
    <express-dialog :sup_this="sup_this" :form.sync="form" :show.sync="show" :expressData="expressData" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import { getChannelByCompanyId } from '@/api/expressAccount'
import { invoiceExamine } from '@/api/invoicingReview'
import searchHeader from './module/header'
import { initDataMixin } from '@/mixins'
import { reviewCols as cols } from './module/cols'
import { parseTime } from '@/utils'
import reviewDialog from './module/reviewDialog'
import expressDialog from './module/expressDialog'
import taxSetting from './module/taxSetting.vue'

export default {
  components: { searchHeader, reviewDialog, expressDialog, taxSetting },
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      sup_this: this,
      url: '/externaladmin/financeService/applyInvoice/list',
      visible: false,
      show: false,
      reviewData: {},
      form: {
        expressShippingMethodId: '',
        expressWaybillCode: '',
        id: '',
        invoiceNumber: ''
      },
      query: {
        invoiceCodeKeyWord: null,
        nickNameKeyWord: '',
        invoiceTitleType: null,
        invoiceTitleKeyWord: '',
        createTime: '',
        invoiceStatus: null,
        orderItems: [
          {
            asc: false,
            column: 'id'
          }
        ]
      },
      sup_this: this,
      expressData: [],
      resetMergeData: {
        id: ''
      }
    }
  },
  methods: {
    parseTime,
    async onReview(data) {
      const { id } = data
      //请求接口，将结果赋值给reviewData
      this.resetMergeData.id = id
      this.visible = true
      // try {
      //   const { detail } = await invoiceExamine({ id })
      //   this.reviewData = detail
      // } catch (e) {
      //   this.$message.error('获取审核数据失败')
      // }
    },
    async search() {
      if (typeof this.query.createTime == 'object') {
        if (this.query.createTime) {
          this.query.applyBeginTime = this.query.createTime[0] + ' 00:00:00'
          this.query.applyEndTime = this.query.createTime[1] + ' 23:59:59'
        } else {
          this.query.applyBeginTime = ''
          this.query.applyEndTime = ''
        }
      }
      await this.searchChange()
    },
    async saveExpress(row) {
      console.log(row)
      //请求接口，将得到的值赋给expressData
      this.form.id = row.id
      this.form.expressShippingMethodId = row.expressShippingMethodId === 0 ? '' : row.expressShippingMethodId
      this.form.expressWaybillCode = row.expressWaybillCode
      this.form.invoiceNumber = row.invoiceNumber
      try {
        if (this.expressData.length === 0) {
          const { detail } = await getChannelByCompanyId({})
          this.expressData = detail
          // this.showLoading[$index] = false
          this.show = true
        } else {
          this.show = true
        }
      } catch (e) {
        this.$message.error('获取物流列表失败')
        this.showLoading[$index] = false
      }

      // if (Object.keys(this.expressData).length === 0) {
      //   this.form = {}
      //   this.show = true
      //   return
      // }
      // //请求有数据时直接赋值
      // const { expressWay, orderNumber } = this.settingData
      // this.$set(this.form, 'expressWay', expressWay)
      // this.$set(this.form, 'orderNumber', orderNumber)
    },
    invoiceStatus(row) {
      let cur
      switch (row.invoiceStatus) {
        case 1:
          cur = '待审核'
          break
        case 2:
          cur = '已审核'
          break
        case 3:
          cur = '拒绝开票'
          break
      }
      return cur
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
.app-container {
  flex-direction: column;
  display: flex;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('taxSetting',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:financialManagement:invoicingReview:set']),expression:"['externaladmin:financialManagement:invoicingReview:set']"}],attrs:{"sup_this":_vm.sup_this}}),_c('searchHeader',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:financialManagement:invoicingReview:search']),expression:"['externaladmin:financialManagement:invoicingReview:search']"}],attrs:{"query":_vm.query},on:{"search":_vm.search}}),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}}),_c('CommonTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"selection":false,"cols":_vm.cols,"infoData":_vm.data},scopedSlots:_vm._u([{key:"typeSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(" "+_vm._s(row.invoiceCategory === 1 ? '普通发票' : '专用发票')+" ")]}},{key:"selectSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(" "+_vm._s(row.invoiceTitleType == 1 ? '企业' : '个人')+" ")]}},{key:"moneySlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(" ￥"+_vm._s(row.invoiceAmount)+" ")]}},{key:"expressSlot",fn:function(ref){
var row = ref.scoped;
return [_c('div',[_vm._v(_vm._s(row.expressName))]),_c('div',[_vm._v(_vm._s(row.expressWaybillCode))])]}},{key:"applyTimeSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(_vm._s(_vm.parseTime(row[row.prop])))]}},{key:"invoiceStatusSlot",fn:function(ref){
var row = ref.scoped;
return [_vm._v(_vm._s(_vm.invoiceStatus(row)))]}}])},[_c('el-table-column',{attrs:{"align":"center","fixed":"right","width":"200","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.invoiceStatus == 1)?_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:financialManagement:invoicingReview:review']),expression:"['externaladmin:financialManagement:invoicingReview:review']"}],staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.onReview(row)}}},[_vm._v("审核")]):_vm._e(),(row.invoiceStatus == 2)?_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:financialManagement:invoicingReview:fedex']),expression:"['externaladmin:financialManagement:invoicingReview:fedex']"}],staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.saveExpress(row)}}},[_vm._v("录入物流")]):_vm._e()]}}])})],1),(_vm.visible)?_c('review-dialog',{attrs:{"resetMergeData":_vm.resetMergeData,"sup_this":_vm.sup_this,"visible":_vm.visible},on:{"update:visible":function($event){_vm.visible=$event}}}):_vm._e(),_c('express-dialog',{attrs:{"sup_this":_vm.sup_this,"form":_vm.form,"show":_vm.show,"expressData":_vm.expressData},on:{"update:form":function($event){_vm.form=$event},"update:show":function($event){_vm.show=$event}}}),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import request from '@/service/request'

// 录入物流
export function addInvoiceExpress(data) {
  return request({
    url: '/externaladmin/financeService/applyInvoice/addInvoiceExpress',
    method: 'post',
    data
  })
}

// 审核显示的数据
export function invoiceExamine(data) {
  return request({
    url: '/externaladmin/financeService/applyInvoice/invoiceExamine',
    method: 'post',
    data
  })
}

// 后台审核发票通过
export function allowInvoice(data) {
  return request({
    url: '/externaladmin/financeService/applyInvoice/allowInvoice',
    method: 'post',
    data
  })
}

// 后台发票审核不通过
export function refuseInvoice(data) {
  return request({
    url: '/externaladmin/financeService/applyInvoice/refuseInvoice',
    method: 'post',
    data
  })
}

// 后台发票导出
export function productExport(data) {
  return request({
    responseType: 'blob',
    url: '/externaladmin/financeService/applyInvoice/exportInvoice',
    method: 'post',
    data
  })
}
